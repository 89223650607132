body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.enlace_desactivado {
  pointer-events: none;
  cursor: default;
}

.div-negro{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-negro-invisible{
  display: none;
}

.invisible{
  display: none;
}

.visible{
  display: block;
}


@media only screen and (min-width:200px) and (max-width:550px){}

@media only screen and (min-width:551px) and (max-width:850px){}

@media only screen and (min-width:851px) and (max-width:1024px){}

@media only screen and (min-width:1025px){}